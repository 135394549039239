import { createApp } from 'vue'
import App from './App.vue'
import './assets/app.css'
import './assets/tailwind.css'


window.$defaultNetwork = 'mainnet';

const app = createApp(App);
app.mount('#app');
