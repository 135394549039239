<template>
  <TheLayout>
    <div v-if="overlayLoading">
      <div class="fixed w-full bg-white left-0 top-0 z-50 bg-opacity-75">
        <div class="flex flex-col justify-center items-center h-screen">
          <div class="flex justify-center">
            <AppLoader>Processing</AppLoader>
          </div>
        </div>
      </div>
    </div>
    <TheConnector v-if="state === 'connect'"/>
    <ThePortal v-if="state === 'portal'"/>
    <AppLoader v-if="isLoading">Loading</AppLoader>
    <AppAlert @show="(showVal) => alertMessage.show	= showVal" :type="alertMessage.type" :details="alertMessage.details" :show="alertMessage.show">{{ alertMessage.title }}
    </AppAlert>
  </TheLayout>
</template>
<script>
import { ref, defineAsyncComponent, onMounted, reactive, provide } from 'vue'


import TheLayout from "./components/TheLayout";
import AppLoader from "./components/AppLoader";

const ThePortal = defineAsyncComponent(() =>
    import('./components/ThePortal')
)
const TheConnector = defineAsyncComponent(() =>
    import('./components/TheConnector')
)

const AppAlert = defineAsyncComponent(() =>
    import('./components/AppAlert')
)
const emitter = require('tiny-emitter/instance');


export default {
  name: 'App',
  components: { AppLoader, TheConnector, ThePortal, TheLayout, AppAlert },

  setup()
  {

    const state = ref('connect')
    const overlayLoading = ref(false)
    provide('state', state)
    provide('balance', ref(0))
    provide('overlayLoading', overlayLoading)

    const isLoading = ref(true)
    const alertMessage = reactive({
      show: false,
      title: null,
      details: null,
      type: null,
    })

    const triggerAlert = (title, type, details = null) => {
      alertMessage.show = true
      alertMessage.title = title
      alertMessage.type = type
      alertMessage.details = details
      if (type !== 'error') {
        setTimeout(() => {
          alertMessage.show = false
        }, 10000)
      }
    }


    onMounted(() => {
      state.value = 'connect'
      isLoading.value = false
      emitter.on('fire-alert', function(title, type, details = null){
        triggerAlert(title, type, details)
      });

    })

    return { state, isLoading, alertMessage, overlayLoading }
  }

}
</script>
