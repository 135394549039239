<template>
  <div class="mt-12">
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
      <slot></slot>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
    </div>
  </div>
</template>
<script setup>
</script>