<template>
  <div class='flex flex-col items-center m-4'>
    <span class='text-indigo-600'><slot></slot></span>
    <div class="flex items-center justify-center ">
      <div class="w-16 h-16 border-b-2 border-indigo-600 rounded-full animate-spin"></div>
    </div>
  </div>
</template>
<script>
export default {
    props: {},
    setup()
    {

    }
}
</script>